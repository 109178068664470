.sign {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 70px 0;
  width: 396px;
}

@media screen and (max-width: 768px) {
  .sign {
    padding: 232px 0;
  }
}

@media screen and (max-width: 450px) {
  .sign {
    padding-top: 56px;
    padding-bottom: 30px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.sign__title {
  margin: 40px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
}

@media screen and (max-width: 450px) {
  .sign__title {
    margin-top: 50px;
    margin-bottom: 80px;
  }
}

.sign__text {
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
  text-align: center;
  color: #8b8b8b;
}

@media screen and (max-width: 450px) {
  .sign__text {
    font-size: 12px;
    line-height: 1.25;
  }
}

.sign__link {
  color: #4285f4;
}
