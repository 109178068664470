.profile {
  margin: 0 auto;
  position: relative;
  padding-top: 74px;
  padding-bottom: 70px;
  width: 410px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .profile {
    padding-top: 236px;
    padding-bottom: 269px;
  }
}

@media screen and (max-width: 450px) {
  .profile {
    padding-top: 70px;
    padding-bottom: 40px;
    width: calc(100% - 60px);
  }
}

.profile__title {
  margin: 0;
  margin-bottom: 123px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .profile__title {
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 320px) {
  .profile__title {
    margin-bottom: 80px;
  }
}

.profile__form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.18;
}

.profile__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 100px;
  grid-template-areas:
    'name input'
    'error error';
  width: 100%;
  margin-bottom: 16px;
}

.profile__row:last-of-type {
  margin-bottom: 0;
  padding-bottom: 194px;
  grid-template-rows: 40px;
}

@media screen and (max-width: 768px) {
  .profile__row:last-of-type {
    padding-bottom: 184px;
  }
}

@media screen and (max-width: 320px) {
  .profile__row:last-of-type {
    padding-bottom: 353px;
  }
}

.profile__label {
  grid-area: name;
  width: 100%;
  height: 14px;
  align-items: center;
}

.profile__input {
  padding: 0;
  grid-area: input;
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-weight: 400;
  text-align: end;
  min-height: 14px;
}

.profile__input::placeholder {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.18;
}

.profile__input:focus::placeholder {
  color: transparent;
}

.profile__input:last-of-type {
  align-self: start;
}

.profile__input-error {
  margin-bottom: -10px;
  display: block;
  grid-area: error;
  width: 100%;
  text-align: end;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: #ee3465;
}

.profile__input-error_bottom {
  margin-top: -22px;
}

.profile__input:focus {
  outline: none;
  border-bottom: 1px solid #8b8b8b;
}

.profile__line {
  margin-top: 0;
  margin-bottom: 17px;
  width: 100%;
  height: 1px;
  background: #424242;
  border: none;
}

.profile__edit {
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.23;
}

.profile__edit_hide {
  display: none;
}

@media screen and (max-width: 320px) {
  .profile__edit {
    font-size: 12px;
    line-height: 1.25;
  }
}

.profile__edit_ok {
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0;
  width: 100%;
  height: 45px;
  border: none;
  background-color: #3456f3;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  text-align: center;
}

.profile__edit_ok-hide {
  display: none;
}

.profile__edit_ok:disabled {
  background-color: #f8f8f8;
  color: #c2c2c2;
}

.profile__exit {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #ee3465;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.23;
}

@media screen and (max-width: 320px) {
  .profile__exit {
    font-size: 12px;
    line-height: 1.25;
  }
}

.error-message_profile {
  position: absolute;
  bottom: 165px;
}

@media screen and (max-width: 768px) {
  .error-message_profile {
    bottom: 365px;
  }
}

@media screen and (max-width: 450px) {
  .error-message_profile {
    bottom: 135px;
  }
}

.error-message_sign-up {
  margin: 0 auto;
  margin-top: 39px;
  position: absolute;
  width: 100%;
}
