.footer {
  /*margin: 0 auto;*/
  padding: 79px 70px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.23;
}

@media screen and (max-width: 900px) {
  .footer {
    padding: 79px 30px 20px;
  }
}

@media screen and (max-width: 320px) {
  .footer {
    padding: 79px 10px 20px;
    font-size: 12px;
    line-height: 1.25;
  }
}

.footer__title {
  margin: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #8b8b8b;
  border-bottom: 1px solid #424242;
}

@media screen and (max-width: 320px) {
  .footer__title {
    margin-bottom: 30px;
    padding-bottom: 21px;
  }
}

.footer__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*align-items: center;*/
}

@media screen and (max-width: 320px) {
  .footer__block {
    flex-direction: column;
    align-items: center;
  }
}

.footer__text {
  margin: 0;
  color: #ffffff;
}

@media screen and (max-width: 320px) {
  .footer__text_year-320 {
    margin-top: 30px;
    order: 1;
    color: #8b8b8b;
  }
}

.footer__links {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media screen and (max-width: 320px) {
  .footer__links {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 30px;
  }
}
