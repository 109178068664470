.search {
  margin: 0;
  margin-bottom: 10px;
  padding: 70px 70px 0px;
}

@media screen and (max-width: 900px) {
  .search {
    padding: 70px 30px 0px;
  }
}

@media screen and (max-width: 400px) {
  .search {
    padding: 70px 14px 0px;
  }
}

.search__container {
  position: relative;
}

.search__input {
  box-sizing: border-box;
  margin-bottom: 26px;
  padding: 25px 110px 25px 25px;
  width: 100%;
  background-color: transparent;
  height: 72px;
  border: 1.5px solid rgba(170, 170, 170, 0.2);
  border-radius: 8px;
  color: #ffffff;
}

.search__input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.11;
  color: #8b8b8b;
}

.search__input:focus {
  outline: 1px solid #8b8b8b;
}

@media screen and (max-width: 400px) {
  .search__input {
    margin-bottom: 31px;
    padding: 20px 141px 20px 12px;
    font-size: 16px;
    height: 56px;
  }

  .search__input::placeholder {
    font-size: 16px;
    line-height: 1;
  }
}

.search__form-input-error {
  margin-top: -20px;
  padding-bottom: 10px;
  display: block;
  width: 100%;
  height: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: #ee3465;
}

.search__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  padding: 0;
  min-width: 100px;
  height: 52px;
  background: #4285f4;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 400px) {
  .search__btn {
    top: 6px;
    right: 6px;
    bottom: 6px;
    min-width: 80px;
    height: 44px;
  }
}
