.switch {
  padding-bottom: 43px;
  border-bottom: 1px solid rgba(170, 170, 170, 0.2);
  display: flex;
  flex-direction: row;
  gap: 14px;
}

@media screen and (max-width: 768px) {
  .switch {
    padding-bottom: 52px;
  }
}

@media screen and (max-width: 320px) {
  .switch {
    padding-bottom: 64px;
  }
}

.switch__container {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  border-radius: 20px;
}

.switch__input {
  height: 0;
  width: 0;
  opacity: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #343434;
  border-radius: 20px;
  transition: 0.5s;
}

.switch__slider::before {
  content: '';
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  transition: 0.5s;
}

.switch__input:checked + .switch__slider {
  background-color: #3ddc84;
}

.switch__input:focus + .switch__slider {
  outline: 1px solid #8b8b8b;
}
.switch__input:checked + .switch__slider::before {
  transform: translateX(16px);
}

.switch__title {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.38;
}

@media screen and (max-width: 400px) {
  .switch__title {
    font-size: 11px;
    line-height: 1.64;
  }
}
