/*Общие стили и стили для множества элементов*/

@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

body {
  /*position: relative;*/
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  background-color: #202020;
  color: #ffffff;
}

/*Списки*/

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*Ссылки*/

.link {
  text-decoration: none;
  cursor: pointer;
  transition: opacity 1s;
}

.link:hover {
  opacity: 0.7;
}

.link_selected {
  font-weight: 500;
}

.link-btn {
  text-decoration: none;
  cursor: pointer;
  transition: opacity 1s;
}

.link-btn:hover {
  opacity: 0.8;
}

.section-title {
  margin: 0;
  margin-bottom: 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.23;
  letter-spacing: -0.04em;
}

@media screen and (max-width: 320px) {
  .section-title {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 1.22;
  }
}

.section-line {
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: #dadada;
  border: none;
}

.error-message {
  margin: 0 auto;
  color: #ff004c;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}
