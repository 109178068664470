.sign__form {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.sign__form-label {
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
  color: #8b8b8b;
}

.sign__form-input {
  margin-top: 10px;
  padding: 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 46px;
  background-color: #2f2f2f;
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.23;
  color: #ffffff;
}

.sign__form-input:focus {
  outline: 1px solid #8b8b8b;
}

.sign__form-input_error:focus {
  outline: 1px solid #ee3465;
}

.sign__form-input-error {
  margin-top: -10px;
  display: block;
  width: 100%;
  height: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: #ee3465;
}

.sign__button:disabled {
  background: #f8f8f8;
  color: #c2c2c2;
}

.sign__button {
  margin: 69px auto 16px;
  padding: 0;
  width: 100%;
  height: 45px;
  background: #4285f4;
  border: none;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 450px) {
  .sign__button {
    margin: 157px auto 14px;
  }
}

.sign__button-in {
  margin-top: 159px;
}

@media screen and (max-width: 320px) {
  .sign__button-in {
    margin-top: 247px;
  }
}

.error-message_sign {
  position: absolute;
  width: 100%;
  bottom: 80px;
}
