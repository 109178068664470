.header {
  padding: 18px 70px;
  height: 74px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 18px 30px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 18px 14px;
  }
}
