.page-404 {
  padding: 245px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .page-404 {
    padding: 408px 0 222px;
  }
}

@media screen and (max-width: 450px) {
  .page-404 {
    padding: 329px 0 30px;
  }
}

.page-404__title {
  margin: 0 auto;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 1.2;
}

@media screen and (max-width: 450px) {
  .page-404__title {
    margin-bottom: 10px;
    font-size: 80px;
    line-height: 1.21;
  }
}

.page-404__text {
  margin: 0 auto;
  margin-bottom: 184px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 450px) {
  .page-404__text {
    margin-bottom: 284px;
    font-size: 12px;
    line-height: 1.25;
  }
}

.page-404__back {
  padding: 0;
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4285f4;
}

@media screen and (max-width: 450px) {
  .page-404__back {
    font-size: 12px;
    line-height: 1.25;
  }
}
